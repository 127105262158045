import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`So many great trails were already uploaded by our user community, that we have
to show you the best new spots for mountain biking already in the middle of
the season.`}</p>
    <br />
    <p>{`More precisely, almost 500 trails were added this season so far, and everyday
there are more and more. We picked out the best new spots you can discover
with `}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{` and narrowed down the field
to 16 destinations, we are going to introduce here in two parts.`}</p>
    <br />
    <p>{`In PART 1 you find Norwegian fjords, Romanian forests and Greek coast trails.`}</p>
    <h2>{`1 - Valldal, Møre og Romsdal, Norway`}</h2>
    <h4>{`15 trails, 25km, Photo: Jonas Modin Rismyhr, Trail: Vardefjellet`}</h4>
    <Image src="news/bestof2018valldal3.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=62.3025&lng=7.2549&coverage=5" mdxType="TrailguidePlugin" />
    <h2>{`2 Vågåmo, Oppland, Norway`}</h2>
    <h4>{`56 trails, 119km, Photo: Øyvind Svare Odde, Trail: Øvre Nordheradsveg til Lye`}</h4>
    <Image src="news/bestof2018vagamo1.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=61.8692&lng=9.1045&coverage=10" mdxType="TrailguidePlugin" />
    <h2>{`3 - Beitostølen, Oppland, Norway`}</h2>
    <h4>{`19 trails, 129km, Photo: `}<a parentName="h4" {...{
        "href": "https://www.lifeofandreas.com"
      }}>{`Andreas Dahl`}</a></h4>
    <Image src="news/bestof2018beitostolen1.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=61.2418&lng=8.9051&coverage=3" mdxType="TrailguidePlugin" />
    <h2>{`4 Nafpaktos, Peloponnisos Dytiki Ellada ke Ionio, Greece:`}</h2>
    <h4>{`8 trails, 9km, Photo: `}<a parentName="h4" {...{
        "href": "https://www.facebook.com/FlowTrailMtb"
      }}>{`George Kaltsis`}</a></h4>
    <Image src="news/bestof2018greece1.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=38.3957&lng=21.7733" mdxType="TrailguidePlugin" />
    <h2>{`5 Brasov, Romania`}</h2>
    <h4>{`7 trails, 66km, Photo: Photo: Dustin Dre Chsler, Trail: Curmatura`}</h4>
    <Image src="news/bestof2018brasov2.jpg" mdxType="Image" />
    <p>{`Find a spot check about Brasov in Romania on
`}<a parentName="p" {...{
        "href": "https://www.trail-business.com/destinations/transylvania"
      }}>{`trail-business.com`}</a></p>
    <TrailguidePlugin content="lat=45.6119&lng=25.5986" mdxType="TrailguidePlugin" />
    <h2>{`6 Willingen, Hessen, Germany`}</h2>
    <h4>{`17 trails, 51km, Photo: Peter Seidl, Trail: Freeridestrecke`}</h4>
    <Image src="news/bestof2018willingen1.jpg" mdxType="Image" />
    <p>{`You can find a spot check of Willingen on Trailguide
`}<a parentName="p" {...{
        "href": "/destination/germany/willingenbrilon"
      }}>{`here`}</a></p>
    <TrailguidePlugin content="lat=51.3227&lng=8.5845&coverage=10" mdxType="TrailguidePlugin" />
    <h2>{`7 Dolomiti Paganella, Trento-Alto Adige, Italy`}</h2>
    <h4>{`17 trails, 95km, Photo: APT Dolomiti Paganella, Trail: From top to lake`}</h4>
    <Image src="news/bestof2018paganella1.jpg" mdxType="Image" />
    <p>{`Check out the spot check of the Dolomiti Paganella Bike on Trailguide
`}<a parentName="p" {...{
        "href": "/destination/italy/paganella"
      }}>{`here`}</a></p>
    <TrailguidePlugin content="lat=46.1521&lng=11.0126&coverage=5" mdxType="TrailguidePlugin" />
    <h2>{`Find new trails`}</h2>
    <Image src="news/sortbylatestaddition.jpg" mdxType="Image" />
    <p>{`On `}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{` you can easily browse the
recently added trails. Opening the app, you see an overview over Europe.
Then select -Sort by latest addition- to see whats new. On the phone it
works the same way. Click on -Trails- to open the list and there you can
change the order.`}</p>
    <br />
    <p>{`It always applies to the area the map shows, so when you zoom into a region,
you see the latest added trails there.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      